import { Injectable } from '@angular/core';
import { Cloudinary } from '@cloudinary/url-gen';
import { environment } from 'src/environments/environment';
import { ASSET_TYPE, Asset } from './data/types/asset.interface';

@Injectable({
  providedIn: 'root',
})
export class CloudinaryService {
  #cld = new Cloudinary({
    cloud: environment.cloudinary,
    url: {
      secure: true,
    },
  });

  constructor() {}

  get cloudinary() {
    return this.#cld;
  }

  url(asset: Asset): string {
    const src = asset.src as string;
    switch (asset.type) {
      case ASSET_TYPE.IMAGE: {
        const img = this.#cld.image(src);
        return img.toURL();
      }
      case ASSET_TYPE.VIDEO: {
        const video = this.#cld.video(src);
        return video.toURL();
      }
      default: {
        throw new Error(`Unsupported asset type "${asset.type}"`);
      }
    }
  }
}
