/**
 * This file is for internal constants only.
 * It is not intended for public use and is not part of the public API
 */
/**
 * @private
 */
export const ALLOWED_URL_CONFIG = ['cname', 'secureDistribution', 'privateCdn', 'signUrl', 'longUrlSignature', 'shorten', 'useRootPath', 'secure', 'forceVersion', 'analytics', 'queryParams'];
/**
 * @private
 */
export const ALLOWED_CLOUD_CONFIG = ['cloudName', 'apiKey', 'apiSecret', 'authToken'];